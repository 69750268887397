import React from 'react'
import { graphql } from 'gatsby'
import initDashTree from './dashutils/initDashTree.js'
import getCardNode from './dashutils/getCardNode.js'
import addCardNode from './dashutils/addCardNode.js'
import * as dashNodeComponents from './dashNodeComponents'
import EntityInfoComponent from './cardComponents/EntityInfoComponent'
import ListLinksComponent from './cardComponents/ListLinksComponent'
import OccupationInfoComponent from './cardComponents/OccupationInfoComponent'
import TodoComponent from './cardComponents/TodoComponent.js'
import PropertyLiteralsComponent from './cardComponents/PropertyLiteralsComponent.js'
import MermaidPropertiesComponent from './cardComponents/MermaidPropertiesComponent.js'
import PropertyRangeComponent from './cardComponents/PropertyRangeComponent.js'
import PropertyDomainComponent from './cardComponents/PropertyDomainComponent.js'
import Seo from '../seo.js'
import DashHeart from '../dashibiden/DashHeart.js'
import getEntityLabel from '../../EntityUtils/getEntityLabel.js'


const components = {
  // generic dashTree properties
  ...dashNodeComponents,
  // generic components
  'EntityInfoComponent': EntityInfoComponent,
  'ListLinksComponent': ListLinksComponent,
  // for esco :
  'OccupationInfoComponent': OccupationInfoComponent,
  
  // utils
  'TodoComponent': TodoComponent,

  // for rdfs properties
  'PropertyLiteralsComponent': PropertyLiteralsComponent,
  'PropertyRangeComponent': PropertyRangeComponent,
  'PropertyDomainComponent': PropertyDomainComponent,
  'MermaidPropertiesComponent': MermaidPropertiesComponent,

}

export const query = graphql`
query RdfPropertyQuery7($id: String) {
  entity: rdfProperty(id: {eq: $id}) {
    id
    label {
      _language
      _value
    }
    status
    domain {
      id
      label {
        _language
        _value
      }
    }
    comment {
      _language
      _value
    }
  }
}
`

// @TODO: rename dashLayoutProperties
export default function DashTreeLayout7(props) {

  const dashTree = initDashTree()

  // explanation: data comme from the page static query and pageData from the fragments embedded in the component
  // create a single object from this 2 sources in "page data"
  const { entity } = props.data
  const { pageData } = props.pageContext
  pageData.entity = entity
  const entityName = getEntityLabel(entity)

  const classLitCard = getCardNode(pageData, 'PropertyLiteralsComponent',
    { cardName: entityName, columnSize: 4, showContribution: true })
  addCardNode(dashTree, classLitCard, 0, 0)

  const mermaid = getCardNode(pageData, 'MermaidPropertiesComponent', { cardName: 'Entity Relationship Représentation (ER Diagram)' })
  addCardNode(dashTree, mermaid, 0, 1)

  const onThisTypesCard = getCardNode(pageData, 'PropertyDomainComponent', { cardName: 'Cette propriété est associée aux classes :', columnSize: 8 })
  addCardNode(dashTree, onThisTypesCard, 1, 0)

  const hasValuesCard = getCardNode(pageData, 'PropertyRangeComponent', { cardName: 'Les valeurs pouvant être associées à cette propriété :', columnSize: 8, })
  addCardNode(dashTree, hasValuesCard, 1, 1)

  return (
    <>
      <Seo title={entityName} />
      <DashHeart dashTree={dashTree} components={components} />
    </>
  );
}
